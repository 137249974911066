import React from "react";

const Footer = () => (
  <div className={"footer"}>
    <div className={"container"}>
      <p>jestwysoko.com 🤘</p>
    </div>
  </div>
);

export default Footer;
